const config = require('../config');
const { getDefaultLocale } = require('./default-locale');

const getLocale = () => {
  if (typeof window === 'undefined') {
    return config.locale;
  }
  return window.localStorage.getItem('language_locale') || getDefaultLocale();
};

const getDefaultCountry = host => {
  const currentHost = host || (typeof window !== 'undefined' && window.location.host);
  if (!currentHost) {
    return 'PL';
  }
  return currentHost === 'gearro.pl' || currentHost === 'www.gearro.pl' ? 'PL' : 'LT';
};

const getDefaultCurrency = userCountry => {
  if (userCountry !== undefined && userCountry === 'PL') return 'PLN';
  if (userCountry !== undefined && userCountry === 'LT') return 'EUR';

  const currentHost = typeof window !== 'undefined' && window.location.host;
  if (!currentHost) return 'PLN';
  return currentHost === 'gearro.pl' || currentHost === 'www.gearro.pl' ? 'PLN' : 'EUR';
};

module.exports = {
  getDefaultLocale,
  getLocale,
  getDefaultCountry,
  getDefaultCurrency,
};
