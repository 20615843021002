import { saveAs } from 'file-saver';
import { getAuthInfo } from './auth';
import { handleError, handleRetry } from './error';
import { Http } from './http';
import { getLocale } from './locale';

export class Api {
  constructor(axiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  currentUser() {
    return this.axiosInstance.get('/users/current');
  }

  phoneVerificationStart(phoneNumber) {
    return this.axiosInstance.post('/phone_verification', { phoneNumber });
  }

  phoneVerificationConfirm(publicId, code) {
    return this.axiosInstance.patch(`/phone_verification/${publicId}`, { code });
  }

  businessVerifactionConfirm(data) {
    return this.axiosInstance.post('/business_payment_accounts', data);
  }

  termsAndConditionsConsent() {
    return this.axiosInstance.post('/terms_and_conditions_consent');
  }

  createPaymentAccount(data) {
    return this.axiosInstance.post('/payment_accounts', data);
  }

  createOrder(data) {
    return this.axiosInstance.post('/orders', data);
  }

  getOrder(id) {
    return this.axiosInstance.get(`/orders/${id}`);
  }

  /**
   * Fetch purchases and sales lists
   * @param {UUID} userId
   * @param {string} type - 'purchases' or 'sales'
   * @returns {Promise<{ message: string, code: number, orders: Order[] }>}
   */
  getOrders(userId, type) {
    return this.axiosInstance.get(`/users/${userId}/orders`, { params: { type } });
  }

  /**
   * Fetch order events
   * @param {UUID} id
   * @returns {Promise<{ message: string, code: number, orderEvents: { title: string, body: string, actions: string[], createdAt: string }[] }>}
   */
  getOrderEvents(id) {
    return this.axiosInstance.get(`/orders/${id}/events`);
  }

  confirmOrderPayment(id) {
    return this.axiosInstance.post(`/orders/${id}/confirm_payment`);
  }

  createOrderShippingAddress(id, data) {
    return this.axiosInstance.post(`/orders/${id}/shipping_addresses`, data);
  }

  createOrderPayment(id) {
    return this.axiosInstance.post(`/orders/${id}/initiate_payment`);
  }

  acceptOrder(id) {
    return this.axiosInstance.post(`/orders/${id}/accept_request`);
  }

  rejectOrder(id) {
    return this.axiosInstance.post(`/orders/${id}/reject_request`);
  }

  markOrderAsOk(id) {
    return this.axiosInstance.post(`/orders/${id}/mark_as_ok`);
  }

  markOrderAsShipped(id) {
    return this.axiosInstance.post(`/orders_dev/${id}/mark_as_shipped`);
  }

  markOrderAsDelivered(id) {
    return this.axiosInstance.post(`/orders_dev/${id}/mark_as_delivered`);
  }

  async getShippingLabel(id) {
    const response = await this.axiosInstance.post('/shippings/labels', undefined, {
      responseType: 'blob',
      params: { orderId: id },
    });
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    saveAs(pdfBlob, `shipping_label_${id}.pdf`);
    return response;
  }

  getCouriersAvailability(id) {
    return this.axiosInstance.get('/shippings/couriers/availability', { params: { orderId: id } });
  }

  requestCourier(id, data) {
    return this.axiosInstance.post('/shippings/couriers', data, { params: { orderId: id } });
  }

  /**
   * Fetch a single listing availability by id
   * @param {UUID} listingId
   * @returns {Promise<{ isAvailable: boolean }>}
   */
  checkItemAvailability(itemId) {
    return this.axiosInstance.get(`/items/${itemId}/available_for_purchase`);
  }

  /**
   * Fetch user wallet balances
   */
  userBalances() {
    return this.axiosInstance.get('/user_balances');
  }

  createBankAccountFromToken(bankAccountToken) {
    return this.axiosInstance.post('/bank_accounts', { bankAccountToken });
  }

  // /payment_identities/status
  getPaymentIdentityStatus() {
    return this.axiosInstance.get('/payment_identities/status');
  }

  // /payment_identities/form_details
  getPaymentIdentityFormDetails() {
    return this.axiosInstance.get('/payment_identities/form_details');
  }

  // /payment_identities/submit
  submitPaymentIdentityForm(data) {
    return this.axiosInstance.post('/payment_identities/submit', data);
  }

  initiatePayout(data) {
    return this.axiosInstance.post('/payouts/initiate', data);
  }

  /**
   * Upload item by url
   * @param {Object} data
   * @param {string} data.itemUrl
   * @param {number} data.price
   * */
  uploadItemByUrl(data) {
    return this.axiosInstance.post('/uploads_by_link', data);
  }

  // /uploads_by_link get all
  getUploadsByUrl() {
    return this.axiosInstance.get('/uploads_by_link');
  }

  getUserProfile(userId) {
    return this.axiosInstance.get(`/users/${userId}`);
  }

  getUserItems(userId) {
    return this.axiosInstance.get(`/users/${userId}/items`);
  }

  deleteItemById(itemId) {
    return this.axiosInstance.delete(`/items/${itemId}`);
  }

  fetchFxRate(fromCurrency, toCurrency) {
    return this.axiosInstance.get(`/currencies/configuration`, { params: { fromCurrency, toCurrency } });
  }

  newsletterSubscribe({ email, origin }) {
    return this.axiosInstance.post('/newsletter/subscribe', { email, origin });
  }

  /**
   * Fetch business users
   * @returns { users: Array<string> }
   */
  businessUsers() {
    return this.axiosInstance.get('/business_users');
  }
}

export const api = new Api(
  new Http(
    {
      baseURL: process.env.REACT_APP_API_URL,
      onError: handleError,
      onRequest: req => {
        if (!req.headers) {
          return req;
        }

        const authInfo = getAuthInfo();
        if (authInfo?.access_token) {
          // eslint-disable-next-line no-param-reassign
          req.headers.Authorization = `Bearer ${authInfo.access_token}`;
        }

        const lang = getLocale();
        if (lang) {
          // eslint-disable-next-line no-param-reassign
          req.headers['X-Language'] = lang;
        }

        return req;
      },
    },
    { onErrorHandler: handleRetry }
  ).client
);
