const { LOCALES } = require('../translations/locales');

const getDefaultLocale = host => {
  const currentHost = host || (typeof window !== 'undefined' && window.location.host);
  if (!currentHost) {
    return LOCALES.POLISH;
  }
  return currentHost === 'nextvelo.pl' ||
    currentHost === 'www.nextvelo.pl' ||
    currentHost === 'gearro.pl' ||
    currentHost === 'www.gearro.pl'
    ? LOCALES.POLISH
    : LOCALES.LITHUANIAN;
};

module.exports = {
  getDefaultLocale,
};
